import { Typography, styled } from '@mui/material'

import type { TypographyProps } from '@mui/material'

export const CursiveTypography = styled(Typography, {
  name: 'CursiveTypography',
  slot: 'Root',
})<TypographyProps>(() => ({
  fontFamily: '"Great Vibes", cursive',
}))

export default CursiveTypography
