import * as React from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button, Container, Typography } from '@mui/material'

import CursiveTypography from '../components/cursive_typography'
import NavBar from '../components/nav_bar'
import SeoHeaders from '../components/seo_headers'

const NotFoundPage = () => (
  <React.Fragment>
    <SeoHeaders
      title='No encontrado'
      description='Contenido no encontrado'
    />
    <NavBar />
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        gap: 2,
        px: 2,
        pt: 2,
        pb: 4,
      }}
    >
      <CursiveTypography
        variant='h1'
      >
        404
      </CursiveTypography>
      <Typography
        variant='h4'
        component='h2'
        textAlign='center'
        gutterBottom
      >
        Página No Encontrada
      </Typography>
      <Button
        variant='contained'
        href='/'
        size='large'
        startIcon={<ArrowBackIcon />}
      >
        Volver al inicio
      </Button>
    </Container>
  </React.Fragment>
)

export default NotFoundPage
